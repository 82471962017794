import React, { useState, useEffect } from "react"
import axios from "axios"

import { useStaticQuery, graphql, Link } from "gatsby"
import HTMLReactParser from "html-react-parser"
const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return email.search(emailRegEx) !== -1
}

const Form = ({ data, drupal_internal__id, settings, onSubmit }) => {
  const infos = useStaticQuery(graphql`
    query formData {
      site {
        siteMetadata {
          drupalUrl
          apikey
        }
      }
    }
  `)
  const [messages, setMessages] = useState([])
  const [errorFields, setErrorFields] = useState([])
  const [formValues, setFormValues] = useState({})
  const [hasError, setHasError] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    console.log(formValues)
    if (errorFields.length > 0) {
      hasErrors()
    }
  }, [formValues])

  const submit = vars => {
    setSending(true)
    axios
      .post(
        `${infos.site.siteMetadata.drupalUrl}/webform_rest/submit`,
        {
          webform_id: drupal_internal__id,
          ...vars,
          sous_site: "Centre Aquatique",
        },
        {
          headers: {
            "api-key": infos.site.siteMetadata.apikey,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        if (res.status == 200) {
          setSending(false)
          submitSuccess()
        }
        console.log(res, "res")
      })
  }

  const hasErrors = () => {
    const newMessages = []
    const errorFieldsTemp = []
    var hasTempError = false

    data.components.map(function (formElement) {
      if (formElement.type === "markup" && formElement.type === "submit") {
        return ""
      }
      var currentValue = formValues[formElement.form_key] || ""
      if (formElement.required === "1" && currentValue === "") {
        hasTempError = true
        newMessages.push('Le champ "' + formElement.name + '" est requis.')
        errorFieldsTemp.push(formElement.form_key)
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            hasTempError = true
            newMessages.push("Email non valide.")
            if (errorFieldsTemp.indexOf(formElement.form_key) < 0) {
              errorFieldsTemp.push(formElement.form_key)
            }
          }
        }
      }
      if (formElement.type === "email") {
        if (!isValidEmail(currentValue)) {
          hasTempError = true
          newMessages.push("Email non valide.")
          if (errorFieldsTemp.indexOf(formElement.form_key) < 0) {
            errorFieldsTemp.push(formElement.form_key)
          }
        }
      }
      if (formElement.verification) {
        let error = formElement.verification(formValues, data)
        if (error) {
          hasTempError = true
          newMessages.push(error)
        }
      }
      return ""
    })
    setErrorFields(errorFieldsTemp)
    setMessages(newMessages)
    setHasError(hasTempError)
    return hasTempError
  }

  const submitSuccess = () => {
    setFormValues({})
    setMessages([settings.confirmation_message])
    setSent(true)
    setTimeout(() => {
      setMessages([])
      setSent(false)
    }, 3000)
    return () => clearTimeout(timer)
  }

  const Item = ({ id, onChange, onSubmit, ...props }) => {
    const [itemValue, setItemValue] = useState()
    const errorClass = errorFields.indexOf(id) < 0 ? "" : " uk-form-danger"
    const propsNames = Object.keys(props)
    const childsProps = {}
    const confsProps = {}
    propsNames.forEach(propName => {
      if (propName.indexOf("#") === 0) {
        confsProps[propName] = props[propName]
      } else {
        childsProps[propName] = props[propName]
      }
    })
    // console.log(props, "props")
    switch (confsProps["#type"]) {
      case "form":
        return (
          <form
            id={id}
            onSubmit={e => {
              e.preventDefault()
              onSubmit(itemValue)
            }}
          >
            {Object.keys(childsProps).map(childId => {
              return (
                <Item
                  key={childId}
                  id={childId}
                  {...props[childId]}
                  onChange={(k, v) => {
                    setItemValue({ ...itemValue, [k]: v })
                    onChange(k, v)
                  }}
                />
              )
            })}
          </form>
        )
        break
      case "container":
        return (
          <div>
            {Object.keys(childsProps).map(childId => {
              return <Item key={childId} id={childId} {...props[childId]} onChange={onChange} />
            })}
          </div>
        )
        break
      case "webform_markup":
        return <>{HTMLReactParser(confsProps["#markup"])}</>
        break

      case "textfield":
        return (
          <div class="uk-margin">
            <div class="uk-form-label">
              <label htmlFor={id}>{props["#title"]}</label>
            </div>

            <input
              className={"uk-input" + errorClass}
              id={id}
              name={id}
              value={itemValue}
              placeholder={props?.["#placeholder"]}
              required={props?.["#required"]}
              onChange={e => {
                setItemValue(e.target.value)
                onChange(id, e.target.value)
              }}
            />
          </div>
        )
        break
      case "email":
        return (
          <div class="uk-margin">
            <div class="uk-form-label">
              <label htmlFor={id}>{props["#title"]}</label>
            </div>
            <input
              type="email"
              className={"uk-input" + errorClass}
              id={id}
              name={id}
              value={itemValue}
              placeholder={props?.["#placeholder"]}
              required={props?.["#required"]}
              onChange={e => {
                setItemValue(e.target.value)
                onChange(id, e.target.value)
              }}
            />
          </div>
        )
        break
      case "textarea":
        return (
          <div class="uk-margin">
            <div class="uk-form-label">
              <label htmlFor={id}>{props["#title"]}</label>
            </div>
            <textarea
              className={"uk-textarea" + errorClass}
              rows="10"
              id={id}
              name={id}
              value={itemValue}
              placeholder={props?.["#placeholder"]}
              required={props?.["#required"]}
              onChange={e => {
                setItemValue(e.target.value)
                onChange(id, e.target.value)
              }}
            />
          </div>
        )
        break
      case "checkbox":
        return (
          <div class="uk-margin">
            <label>
              <input
                class="uk-checkbox"
                type="checkbox"
                onChange={e => {
                  setItemValue(e.target.value)
                  onChange(id, e.target.checked ? e.target.value : "")
                }}
                checked={itemValue}
                required={props?.["#required"]}
              />{" "}
              {props?.["#title"]}
            </label>
          </div>
        )
        break
      case "webform_actions":
        return (
          <div class="uk-margin">
            <button type="submit" className="uk-button uk-button-primary">
              {props?.["#submit__label"]}
            </button>
          </div>
        )
        break
      default:
        break
    }
    return null
  }
  const form = { "#type": "form", id: "form", ...data }

  return (
    <div>
      {messages.length > 0 && (
        <div className={hasError ? "uk-alert-danger" : "uk-alert-success"} uk-alert="">
          <a
            className="uk-alert-close"
            uk-close=""
            onClick={() => {
              setMessages([])
              if (sent) {
                setSent(false)
              }
            }}
          ></a>
          {messages.map((message, index) => (
            <div key={`contact-form-message-${index}`}>{HTMLReactParser(message)}</div>
          ))}
        </div>
      )}
      {sending && (
        <div className="">
          <p>Envoi en cours...</p>
        </div>
      )}
      <Item
        key="main"
        id="main"
        {...form}
        onChange={e => console.log(e)}
        onSubmit={vars => {
          console.log(vars, "les values")
          submit(vars)
        }}
      />
    </div>
  )
}
export default Form
