import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import FormD9 from "../components/FormD9"
import yaml from "js-yaml"
import { getValueD9 } from "../utils"
const PageTemplate = props => {
  const data = useStaticQuery(graphql`
    {
      groupSousSite {
        field_email
        field_address_text {
          value
          processed
          format
        }
        field_subtitle
        field_phone
        relationships {
          field_ss_contact {
            id
            elements
            settings {
              confirmation_title
              confirmation_message
            }
            status
            title
            drupal_id
            drupal_internal__id
          }
        }
      }
    }
  `)

  const obj = yaml.load(data.groupSousSite.relationships.field_ss_contact.elements)

  return (
    <Layout title="Contact" {...props} description="Contact">
      <div className="uk-section uk-padding uk-background-default">
        <FormD9 data={obj} {...data.groupSousSite.relationships.field_ss_contact} />
        <div>
          <div className="uk-flex uk-flex-column">
            <p style={{ marginBottom: 0, marginTop: 50 }}>
              <span
                className="uk-icon uk-icon-image uk-margin-small-right"
                style={{ backgroundImage: "url(/svg/location-outline-grey.svg)" }}
              ></span>
              <span className="uk-text-muted">
                {getValueD9(data.groupSousSite, "field_address_text.processed", "").replace(
                  /<[^>]*>?/gm,
                  ""
                )}
              </span>
            </p>
            <p style={{ marginBottom: 0, marginTop: 20 }}>
              <span
                className="uk-icon uk-icon-image uk-margin-small-right"
                style={{ backgroundImage: "url(/svg/mail-grey.svg)" }}
              ></span>
              <span className="uk-text-muted">
                {getValueD9(data.groupSousSite, "field_email", "")}
              </span>
            </p>
            <p style={{ marginBottom: 0, marginTop: 20 }}>
              <a href={"tel:+687" + getValueD9(data.groupSousSite, "field_phone", "")}>
                <span
                  className="uk-icon uk-icon-image uk-margin-small-right"
                  style={{ backgroundImage: "url(/svg/phone-grey.svg)" }}
                ></span>
                <span className="uk-text-muted">
                  (+687){" "}
                  {("" + getValueD9(data.groupSousSite, "field_phone", ""))
                    .match(/.{1,2}/g)
                    .join(" ")}
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default PageTemplate
